<script setup lang="ts"></script>

<template>
  <ping />
  <notifications width="600px" position="top center">
    <template #body="props">
      <div class="pinotqr-notification" :class="props.item.type">
        <div class="icon">
          <font-awesome
            v-if="props.item.type === 'success'"
            icon="check"
            class="pinotqr-notification-icon"
          />
          <font-awesome v-else icon="xmark" class="pinotqr-notification-icon" />
        </div>
        <div v-html="props.item.text" />
      </div>
    </template>
  </notifications>
  <router-view></router-view>
</template>

<style scoped></style>
