<template>
  <!-- Your component's template goes here -->
</template>

<script>
import { ref, watch, onBeforeUnmount } from "vue";
import axios from "axios";

export default {
  setup() {
    const intervalId = ref(null);
    const totalTime = ref(0);
    const maxTime = 30 * 60 * 1000; // 30 minutes in milliseconds

    const pingEndpoint = () => {
      const config = useRuntimeConfig();
      axios.get(config.public.API_BASE_URL + "/ping");
    };

    const startPingInterval = () => {
      pingEndpoint(); // Initial ping

      intervalId.value = setInterval(
        () => {
          totalTime.value += 4 * 60 * 1000; // 4 minutes in milliseconds
          if (totalTime.value >= maxTime) {
            clearInterval(intervalId.value);
          } else {
            pingEndpoint();
          }
        },
        4 * 60 * 1000,
      );
    };

    const stopPingInterval = () => {
      clearInterval(intervalId.value);
    };

    // Automatically stop the interval when the component is unmounted
    onBeforeUnmount(() => {
      stopPingInterval();
    });

    // Start the ping interval when the component is mounted
    watchEffect(() => {
      startPingInterval();
    });

    return {
      intervalId,
      totalTime,
      maxTime,
    };
  },
};
</script>
